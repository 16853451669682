.App {
	text-align: left;
	width: 100%;
}

.App-header {
	background-color: #ffffff;
	min-height: 10vh;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: #000000;
}
.App-nav {
	background-color: rgba(87, 87, 87, 0.37);
	min-height: 10vh;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: #000000;
}
.App-main {
	background-color: #eeeeee;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: #000000;
}
.App-footer {
	background-color: #ffffff;
	min-height: 10vh;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: #000000;
}


@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to { 
        transform: rotate(360deg);
    }
}
 

 @-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }
    to { 
        -webkit-transform: rotate(360deg);
    }
}

.load {
	width: 100px;
	height: 100px;
	margin: 110px auto 0;
	border:solid 10px #8822aa;
	border-radius: 50%;
	border-right-color: transparent;
	border-bottom-color: transparent;
	 -webkit-transition: all 0.5s ease-in;
    -webkit-animation-name:             rotate; 
    -webkit-animation-duration:         1.0s; 
    -webkit-animation-iteration-count:  infinite;
    -webkit-animation-timing-function: linear;
    	
    	 transition: all 0.5s ease-in;
    animation-name:             rotate; 
    animation-duration:         1.0s; 
    animation-iteration-count:  infinite;
    animation-timing-function: linear; 
}


.MuiPopover-paper {
	max-height: "385px !important";
}